<template>
  <div class="row">
    <div class="col-md-12">
      <call-center-lead-datatable/>
    </div>
  </div>
</template>

<script>
import CallCenterLeadDatatable from "@/pages/Leads/datatables/CallCenterLeadDatatable";

export default {
  name: "LeadProgressIndexPage",
  components: {CallCenterLeadDatatable},
  data() {
    return {
      headerButtons: [
        {
          label: 'add',
          onClick: 'onNewCallCenterLead',
          type: 'primary'
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>
